<template>
  <div>
    <v-img
      height="400px"
      :gradient="imageGradient"
      :src="image"
    >
      <v-card-text
      class="white--text display-2 font-weight-bold d-flex align-center justify-center fill-height">
        Conectamos novos negócios
      </v-card-text>
    </v-img>
    <div>
      <v-container class="mkt-content-container mt-10">
        <v-row class="body-2 mb-3">
          <v-col cols="12">
            <router-link to="/home">
              Home
            </router-link>
            >
            <span>
            Quem somos
          </span>
          </v-col>
        </v-row>
        <v-row class="mt-10">
          <v-col cols="12" sm="6" class="pr-12">
            <span class="display-1 text--primary--color font-weight-bold">
              Quem somos?
            </span>
            <p class="mt-10 text-justify">
              Surgimos em 2019 originalmente como uma plataforma de cotações que tinha como objetivo
              facilitar o envio e a gestão de compras. A plataforma contava com diversas
              funcionalidades que ajudavam os compradores a tomar a melhor decisão possível e de
              um jeito rápido e assertivo.
            </p>
            <p class="text-justify">
              Contudo, ao longo do tempo e com o constante feedback de nossos clientes, tivemos a
              ideia de construir algo mais simples e rápido. Nós acreditávamos que poderíamos
              facilitar ainda mais a vida dos compradores e fornecedores e por isso nasceu o
              marketplace.
            </p>
            <p class="text-justify">
              No marketplace, o comprador tem mais agilidade, praticidade e menos burocracia para
              pesquisar e avaliar os produtos que deseja comprar. É possível avaliar os preços de
              vários fornecedores e escolher o produto que melhor te atende, além de analisar as
              formas de pagamento e prazos de entrega direto no site. Sem precisar fazer qualquer
              contato com o fornecedor para ter esses dados.
            </p>
            <p class="text-justify">
              Já para o fornecedor, oportunizamos que eles estejam vendendo e divulgando seus
              produtos online, sem investimento inicial. Assim o fornecedor terá um canal de
              venda online para empresas, proporcionando presença digital, tráfego e maiores vendas.
            </p>
          </v-col>
          <v-col cols="12" sm="6" v-if="!isMobile">
            <v-img class="rounded-card" :src="quemSomosImage"/>
          </v-col>
        </v-row>
        <px-vantagens class="mt-24" title="Vantagens em utilizar a Proxpect"/>
        <px-como-funciona class="mt-24"/>
      </v-container>
    </div>
  </div>
</template>

<script>
import gridMixin from '@/mixins/grid-mixin';
import Vantagens from '@/modules/institucional/Vantagens.vue';
import ComoFunciona from '@/modules/institucional/ComoFunciona.vue';
import capaImage from '../../assets/banner-institucional.jpg';
import quemSomosImage from '../../assets/foto-institucional.jpg';

export default {
  mixins: [gridMixin],
  data() {
    return {
      imageGradient: this.$pxMarketplace.DEFAULT_IMAGE_GRADIENT,
      image: capaImage,
      quemSomosImage,
    };
  },
  components: {
    pxVantagens: Vantagens,
    pxComoFunciona: ComoFunciona,
  },
};
</script>
